import * as React from "react";
import { trimAddress } from "../../utils";
import "./LeaderBoard.scss";

import { LeaderType, NFTInfo } from "../../services/NFTInfo";
import { PaginateButtons, Pagination } from "../Items/Pagination";

interface IPolakRow {
  data: LeaderType;
}
const PolakRow = ({ data }: IPolakRow) => {
  return (
    <tr key={data.address} className="radius polak-row">
      <td>{data.counter}</td>
      <td>
        <img src={data.avatar} alt="" className="polak-row__image" />
      </td>
      <td>{trimAddress(data.address)}</td>
      <td>{data.count}</td>
    </tr>
  );
};

export const LeaderBoard = () => {
  const [polacyList, setPolacyList] = React.useState<LeaderType[]>([]);
  const [pages, setPages] = React.useState<number>(0);
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  const dataLimit = 10;

  const nextPage = () => {
    if (currentPage + 1 === pages) setCurrentPage(0);
    setCurrentPage(page => page + 1);
  };

  const prevPage = () => {
    if (currentPage - 1 === 0) setCurrentPage(pages - 1);
    setCurrentPage(page => page - 1);
  };

  React.useEffect(() => {
    const fetchLeaders = async () => {
      const polacy = await NFTInfo.getOwnersDetails();
      if (polacy instanceof Error) {
        setPolacyList([]);
        return;
      }

      const pages = Math.round(polacy.length / dataLimit) + 1;
      setPolacyList(polacy);
      setPages(pages);
    };
    fetchLeaders();
  }, []);

  return (
    <div className="leaderboard-body radius theshadow leader-board">
      <table className="table leader-board__table">
        <thead className="leader-board__thead">
          <tr className="leader-board__tr">
            <th className="col">#</th>
            <th className="col leader-board__thead-col-pole">ThePolak</th>
            <th className="col leader-board__thead-col-owner">Posiadacz</th>
            <th className="col">Liczba NFT</th>
          </tr>
        </thead>
        <tbody className="leader-board__tbody">
          <Pagination
            data={polacyList}
            RenderComponent={PolakRow}
            title=""
            dataLimit={10}
            currentPage={currentPage}
          />
        </tbody>
      </table>
      <PaginateButtons
        prevPage={prevPage}
        nextPage={nextPage}
        currentPage={currentPage}
        pages={pages}
      />
    </div>
  );
};
