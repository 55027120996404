import * as React from "react";
import { IMemeItem, MemeItem } from "./MemeItem/MemeItem";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import sliderLeftArrow from "../images/slider-left-arrow.svg";
import sliderRightArrow from "../images/slider-right-arrow.svg";
import { Memes } from "../types";
import { MemesService } from "../services/Memes";
import { TwitterTweetEmbed } from "react-twitter-embed";

{
  /* <TwitterTweetEmbed tweetId={"1631213785603080193"} /> */
}
const responsive = {
  0: { items: 1 },
  850: { items: 1.5 },
  1100: { items: 2 },
  1400: { items: 2.5 },
  1550: { items: 3 },
  2000: { items: 3.5 },
};

export const CarouselMemes = ({ items }: { items: Memes[] }) => {
  const memes = [];
  for (let tweet of items) {
    memes.push(
      // <div className="tweet-item">
      <TwitterTweetEmbed tweetId={tweet.TweetId} />
      // </div>
    );
  }

  return (
    <>
      <AliceCarousel
        mouseTracking
        items={memes}
        responsive={responsive}
        controlsStrategy=""
        paddingLeft={0}
        autoPlay={true}
        autoPlayInterval={5000}
        infinite={true}
        keyboardNavigation={true}
        animationDuration={400}
        animationType="slide"
        renderPrevButton={() => {
          return (
            <button>
              <img src={sliderLeftArrow} alt="" />
            </button>
          );
        }}
        renderNextButton={() => {
          return (
            <button>
              <img src={sliderRightArrow} alt="" />
            </button>
          );
        }}
      />
    </>
  );
};
