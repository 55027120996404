import { Link } from "gatsby";
import * as React from "react";
import onion from "../../../images/onion.svg";
import { MemesService } from "../../../services/Memes";
import { Memes } from "../../../types";
import { CarouselMemes } from "../../CarouselMemes";
import { YellowButton } from "../../Items/Buttons/Buttons";
import { MemeItem } from "../../MemeItem/MemeItem";
import "./MemesSection.scss";

// const fakeTwitts = Array(3).fill({
//   name: "The Polacy 🇵🇱",
//   username: "ThePolacy",
//   mediaUrl:
//     "https://ipfs.io/ipfs/QmeaYvyXF8Zm98V48TnxqdKZikrkncSkMn2E6EfQF15E4h/255.png",
//   description:
//     "DD Polska!\n\nNie wiem jak tam Wasze barometry ale nasze wskazuja, że dziś środa.\nPowodzenia we wszystkim co robicie.\nZ fartem! 🫡🇵🇱",
//   isImage: true,
// });

// fakeTwitts.push({
//   name: "The Polacy 🇵🇱",
//   username: "ThePolacy",
//   mediaUrl:
//     "https://fodi.sandbox.dogtronic.dev/wp-content/uploads/2022/12/video.mp4",
//   description:
//     "DD Polska!\n\nNie wiem jak tam Wasze barometry ale nasze wskazuja, że dziś środa.\nPowodzenia we wszystkim co robicie.\nZ fartem! 🫡🇵🇱",
//   isImage: false,
// });

// fakeTwitts.push({
//   name: "The Polacy 🇵🇱",
//   username: "ThePolacy",
//   mediaUrl:
//     "https://fodi.sandbox.dogtronic.dev/wp-content/uploads/2022/12/video.mp4",
//   description:
//     "DD Polska!\n\nNie wiem jak tam Wasze barometry ale nasze wskazuja, że dziś środa.\nPowodzenia we wszystkim co robicie.\nZ fartem! 🫡🇵🇱",
//   isImage: false,
// });

export const MemesSection = ({ serverData }: { serverData: Memes[] }) => {
  return (
    <>
      {" "}
      <section className="memes-section memes-section-box" id="memes">
        <div
          className="your-memes memes-section-box__frame theshadow aos-animate aos-after"
          data-aos="fade-up"
        >
          <span className="memes-section-box__header">Wasze memy</span>
          <p className="memes-section-box__first-description">
            Przedstawiamy Wam zbiór memów stworzonych przez naszą społeczność,
            które w przeciwieństwie do kabaretów sprawią, że się przynajmniej
            raz zaśmiejecie.
          </p>

          <p>
            Chcesz, żeby Twój mem trafił na ścianę sławy? <br />
            <strong>
              Oznacz swój post na twitterze hasztagiem #ThePolacymeme
            </strong>
          </p>

          <button className="memes-section-box__button theshadow">
            #ThePolacymeme
          </button>
          <img
            className="memes-section-box__image"
            src={onion}
            alt="Cebula <3"
          />
        </div>
      </section>
      <section className="tweets-section">
        <CarouselMemes items={serverData} />
        <Link
          to="/memy"
          className="timetable-section__show-all-button yellow-btn theshadow btn-clicked"
        >
          Jedyny prawdziwy przegląd memów
        </Link>
      </section>
    </>
  );
};
