import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { Timetable, TimetableType } from "../../../services/Timetable";
import { TimetableItem } from "../../TimetableItem/TimetableItem";
import mainTimetableImage from "../../../images/main-timetable-header-bus-2.png";

import partyStar from "../../../images/partystar.svg";
import { SectionImage } from "../../Items/Images/Images";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Link } from "gatsby";
import "./TimetableSection.scss";

export const TimetableSection = () => {
  const [timetableList, setTimetableList] = React.useState<TimetableType[]>([]);
  const isSmallScreen = useMediaQuery({ maxWidth: "1350px" });
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    const fetchTimetable = async () => {
      try {
        const response = await Timetable.getTimetableEvents();
        if (response instanceof Error) throw response;

        if (response.length > 3) {
          const firstThree = response.slice(0, 3);
          setTimetableList(firstThree);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchTimetable();
  }, []);

  return (
    <section className="timetable timetable-section-box" id="timetable">
      <SectionImage
        imageUrl={partyStar}
        alt={"Party Star :)"}
        className="timetable-section-box__image"
      />
      <header className="timetable-section-box__header timetable-section">
        <span className="title timetable-section-box__first-description">
          Jaki rozkład jazdy
        </span>
        <p className="timetable-section-box__second-description">
          ThePolacy to istoty stadne, dlatego regularne spotkania są istotą
          naszej społeczności. Wspólne biesiadowanie, opowiadanie o ostatnim
          gemie 100x czy dzielenie się najgorszymi rug pullowymi historami to
          coś co nas spaja i tworzy charakter. Nie zamulaj i wpadnij na wspólny
          melanż byś, cytując klasyka, mógł “przeżyć to sam”.
        </p>
      </header>

      <div
        className="timetable-section-box__events-box aos-animate aos-after"
        data-aos="fade-up"
      >
        {timetableList.length >= 3 && (
          <>
            <TimetableItem
              isClosed={timetableList[0].Status ?? false}
              image={timetableList[0].ImageUrl ?? mainTimetableImage}
              city={timetableList[0].City}
              date={timetableList[0].Date}
              time={timetableList[0].Time}
              place={timetableList[0].Place}
              title={timetableList[0].Title}
              description={timetableList[0].Description}
              actionUrl={""}
              isMainTimetableItem={!isSmallScreen}
              eventId={-1}
              className={
                isSmallScreen
                  ? ""
                  : "timetable-section-box__main-event timetable-main-item"
              }
              isStillReceivingTickets={
                timetableList[0].StillReceivingTickets ?? false
              }
              address={undefined}
              TicketReceivingActive={timetableList[0].TicketReceivingActive}
            />
            <div className="timetable-section-box__additional-events">
              <TimetableItem
                isClosed={timetableList[1].Status ?? false}
                image={timetableList[1].ImageUrl ?? mainTimetableImage}
                city={timetableList[1].City}
                date={timetableList[1].Date}
                time={timetableList[1].Time}
                place={timetableList[1].Place}
                title={timetableList[1].Title}
                description={timetableList[1].Description}
                actionUrl={""}
                isMainTimetableItem={false}
                eventId={-1}
                isStillReceivingTickets={
                  timetableList[1].StillReceivingTickets ?? false
                }
                address={undefined}
                TicketReceivingActive={timetableList[1].TicketReceivingActive}
              />
              <div ref={inputRef}>
                <TimetableItem
                  isClosed={timetableList[2].Status ?? false}
                  image={timetableList[2].ImageUrl ?? mainTimetableImage}
                  city={timetableList[2].City}
                  date={timetableList[2].Date}
                  time={timetableList[2].Time}
                  place={timetableList[2].Place}
                  title={timetableList[2].Title}
                  description={timetableList[2].Description}
                  actionUrl={""}
                  isMainTimetableItem={false}
                  eventId={-1}
                  isStillReceivingTickets={
                    timetableList[2].StillReceivingTickets ?? false
                  }
                  address={undefined}
                  TicketReceivingActive={timetableList[2].TicketReceivingActive}
                />
              </div>
            </div>
          </>
        )}
      </div>

      <Link
        to="/timetable"
        className="timetable-section-box__button yellow-btn theshadow btn-clicked"
      >
        Zobacz wszystkie wydarzenia
      </Link>
    </section>
  );
};
