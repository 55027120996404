import * as React from "react";
import nft1727 from "../../../images/nft-examples/1727.svg";
import nft2 from "../../../images/nft-examples/2.svg";
import nft3 from "../../../images/nft-examples/3.svg";
import nft4 from "../../../images/nft-examples/4.svg";
import "./PolacyExamplesSection.scss";

export const PolacyExamplesSection = () => {
  return (
    <section className="poles-examples-section">
      <div className="the-polacy-examples poles-examples-section__box">
        <div className="polak-details poles-examples-section__one theshadow">
          <div className="poles-examples-section__one-details">
            <span className="poles-examples-section__one-name">
              The Polak #1727
            </span>
            <img
              className="poles-examples-section__one-image"
              src={nft1727}
              alt=""
            />
            <span className="poles-examples-section__one-title">
              1# Oryginalni i jedyni tacy
            </span>
            <p className="poles-examples-section__one-description">
              Tu znajdziecie wszystko: barwy narodowe, skrzydła Husarii, cebulę,
              sercę WOŚP, kapelusz góralski, wódkę Wyborową, napis KUR*A, włosy
              detektywa Rutkowskiego, wąs Korwina, zajebiste pekaesy Killera,
              fajkę, zioło, bandany narodowe i klubowe, opaleniznę budowlańca, a
              nawet widły, maczetę i ciupagę!
            </p>
          </div>
        </div>

        <div className="polak-details poles-examples-section__one theshadow">
          <div className="poles-examples-section__one-details">
            <span className="poles-examples-section__one-name">
              The Polak #1727
            </span>
            <img
              className="poles-examples-section__one-image"
              src={nft2}
              alt=""
            />
            <span className="poles-examples-section__one-title">
              2# Polskie Web3 Zjednoczone
            </span>
            <p className="poles-examples-section__one-description">
              Czas przechylić kufel prawdziwego polskiego piwa i skosztować
              miodu pitnego! Wznosimy toast “na zdrowie”, “sto lat”! The Polacy
              dokonali czegoś niesamowitego - podbili WEB3 w Polsce oraz skupili
              na sobie uwagę wielu influencerów i prezesów znanych firm.
            </p>
          </div>
        </div>

        <div className="polak-details poles-examples-section__one theshadow">
          <div className="poles-examples-section__one-details">
            <span className="poles-examples-section__one-name">
              The Polak #1727
            </span>
            <img
              className="poles-examples-section__one-image"
              src={nft3}
              alt=""
            />
            <span className="poles-examples-section__one-title">
              3# Polska Kultura
            </span>
            <p className="poles-examples-section__one-description">
              Polska kultura, bogata i żywa, stanowi ważną część zachodniej
              cywilizacji i zachodniego świata, wnosząc znaczący wkład w sztukę,
              muzykę, filozofię, matematykę, naukę, politykę i literaturę. Nasza
              społeczność to sama śmietanka towarzyska Polski i Europy.
            </p>
          </div>
        </div>

        <div className="polak-details poles-examples-section__one theshadow">
          <div className="poles-examples-section__one-details">
            <span className="poles-examples-section__one-name">
              The Polak #1727
            </span>
            <img
              className="poles-examples-section__one-image"
              src={nft4}
              alt=""
            />
            <span className="poles-examples-section__one-title">4# WAGMI</span>
            <p className="poles-examples-section__one-description">
              ThePolacy zrzeszają nie tylko wybitne postacie polskiego web3 ale
              też najbardziej degeńskich Degenów w kraju. Nie jesteś metalem,
              punkiem, kibolem, lewakiem, katolem czy prawolem? To znak, że
              jesteś DEGENEM.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
