import * as React from "react";
import awesomeHat from "../../../images/awesome-hat.svg";
import { NFTInfo, PolacySummary } from "../../../services/NFTInfo";
import { SectionImage } from "../../Items/Images/Images";
import { ModalWrapper } from "../Modals/ModalWrapper/ModalWrapper";
import "./PolacySummarySection.scss";
import BigNumber from "bignumber.js";
import { formatCompactNumber } from "../../../utils";

export const PolacySummarySection = () => {
  const [summary, setSummary] = React.useState<PolacySummary>();
  // const [showGame, setShowGame] = React.useState(false);

  React.useEffect(() => {
    const fetchNFTsDetails = async () => {
      const polacySummary = await NFTInfo.getPolacySummary();
      setSummary(polacySummary);
    };

    fetchNFTsDetails();
  }, []);
  return (
    <section className="summary-section poles-summary-section">
      {/* <ModalWrapper
        isVisible={showGame}
        action={function (): void {
          setShowGame(false);
        }}
        ChosenModal={
          <iframe
            width={"70%"}
            height="60%"
            src="https://thepolacy-game-production.up.railway.app/"
          />
        }
      /> */}

      <div className="poles-summary-section__box">
        <SectionImage
          imageUrl={awesomeHat}
          alt={"Polski kapelusz :)"}
          className="poles-summary-section__image"
        />
        <div className="poles-summary-section__content title">
          <span
            className="poles-summary-section__header"
            // onClick={() => setShowGame(true)}
          >
            Prestiżowa ekipa Polaków on-chain
          </span>
          <p className="poles-summary-section__description">
            Jeśli miałeś jakiekolwiek wątpliwości, gdzie leży siła Narodu
            Polskiego możesz zrzucić ciężar niewiedzy - oto są oni! Wielcy!
            Sarmaci, bywalcy na europejskich salonach!
          </p>
          <a
            href="https://opensea.io/collection/thepolacy"
            rel="noopener noreferrer"
            target="_blank"
            className="poles-summary-section__button red-btn theshadow"
          >
            Kup na OpenSea
          </a>
        </div>

        <div className="boxes poles-summary-section__boxes ">
          <div className="poles-summary-section__first-box box theshadow">
            <span className="label">Liczba Polaków</span>
            <span className="value red">{summary?.totalNFTNumber}</span>
          </div>
          <div className="box poles-summary-section__second-box theshadow">
            <span className="label">Liczba portfeli</span>
            <span className="value blue">{summary?.totalWalletsNumber}</span>
          </div>
          <div className="box poles-summary-section__third-box theshadow">
            <span className="label">Wolumen</span>
            <span className="value blue amount-num">
              {formatCompactNumber(summary?.plnCollectionPrice ?? 93) ?? "93"}
              <span className="poles-summary-section__label">&nbsp; PLN</span>
            </span>
          </div>
          <div className="box poles-summary-section__fourth-box theshadow">
            <span className="label">Floor price</span>
            <span className="value blue">
              {new BigNumber(summary?.floorPrice ?? "0.07").toFixed(3)}
              {/* {formatCompactNumber(summary?.plnFloorNFT ?? 93) ?? "93"} */}
              <span className="poles-summary-section__label">&nbsp; ETH</span>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};
