import * as React from "react";
import starWithGlasses from "../../../images/star-with-glasses.svg";
import "./ReasonsSection.scss";

export const ReasonsSection = () => (
  <section className="reasons-section" id="reasons">
    <div
      className="reasons theshadow aos-animate aos-after reason-section-box"
      data-aos="zoom-in"
    >
      <img
        className="reason-section-box__image"
        src={starWithGlasses}
        alt="Star with glasses"
      />
      <span className="reason-section-box__header">4 powody</span>
      <span className="reason-section-box__first-description">
        Dlaczego warto posiadać ThePolaka?
      </span>
      <p className="reason-section-box__second-description">
        Są 4 powody, dla których warto posiadać The Polaka NFT! W zasadzie to
        jest ich znacznie więcej ale brakło nam miejsca na stronie.
      </p>
    </div>
  </section>
);
