import * as React from "react";
import { HeadFC } from "gatsby";

import mainHeaderThePolacy from "../images/header-bg.svg";
import secondHeaderThePolacy from "../images/gory-polacy-2.png";
import { useWeb3Provider, Web3Providers } from "../contexts/AppContext";

import { NavBar } from "../components/NavBar/NavBar";

import AOS from "aos";
import "aos/dist/aos.css";
import { TimetableSection } from "../components/IndexSections/TimetableSection/TimetableSection";
import { ReasonsSection } from "../components/IndexSections/ReasonsSection/ReasonsSection";
import { PolacyExamplesSection } from "../components/IndexSections/PolacyExamplesSection/PolacyExamplesSection";
import { PartnersSection } from "../components/IndexSections/PartnersSection/PartnersSection";
import { MemesSection } from "../components/IndexSections/MemesSection/MemesSection";
import { MerchSection } from "../components/IndexSections/MerchSection/MerchSection";
import { DropsSection } from "../components/IndexSections/DropsSection/DropsSection";
import { LeaderBoardSection } from "../components/IndexSections/LeaderBoardSection/LeaderBoardSection";
import { BlogSection } from "../components/IndexSections/BlogSection/BlogSection";
import { PolacySummarySection } from "../components/IndexSections/PolacySummarySection/PolacySummarySection";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowUp } from "@fortawesome/free-solid-svg-icons";
import DashboardPage from "./dashboard";

import Title from "../images/Title.svg";
import { Footer } from "../components/Footer/Footer";
import { Timetable, TimetableType } from "../services/Timetable";
import Marquee from "react-fast-marquee";
import { Announce, Announcements } from "../services/Announce";
import { AnnounceMarquee } from "../components/AnnounceMarquee/AnnonuceMarquee";
import { Memes } from "../types";
import { MemesService } from "../services/Memes";
import { YellowButton } from "../components/Items/Buttons/Buttons";
import { ModalWrapper } from "../components/IndexSections/Modals/ModalWrapper/ModalWrapper";
import { SEO } from "../components/SEO";
import Linktree from "../images/linktree.svg";

const IndexPage = ({ serverData }: any) => {
  return (
    <Web3Providers>
      <IndexPageComponent serverData={serverData} />
    </Web3Providers>
  );
};

const IndexPageComponent = ({
  serverData,
}: {
  serverData: {
    announcements: Announcements[];
    memes: Memes[];
  };
}) => {
  const { accountDetails, ethProvider } = useWeb3Provider();
  const [showGame, setShowGame] = React.useState(false);

  React.useEffect(() => {
    AOS.init({
      offset: -300,
      duration: 500,
      easing: "ease-in-sine",
      delay: 100,
      disable: "mobile",
    });
    AOS.refresh();
  }, []);

  let mainPageContent = (
    <>
      <Web3Providers>
        <main className="index-view">
          <NavBar serverData={serverData?.announcements ?? []} />
          <section className="index-view__first-section">
            <div className="index-view__first-section-box main-header radius theshadow">
              <div className="index-view__first-section-linktree-logo ">
                <a
                  onClick={() =>
                    window.open("http://www.linktree.com/thepolacy")
                  }
                >
                  <img src={Linktree} alt="linktree logo" />
                </a>
              </div>
              <img
                className="index-view__first-section-image"
                src={Title}
                alt="The polacy first image"
              />
              <p className="index-view__first-section-description text-extrabold">
                Kolekcja 2137 Polaków NFT szarżuje po blockchainie Ethereum
                niczym elitarna polska skrzydlata Husaria, by siać strach,
                budować chwałę i odnieść spektakularne zwycięstwo!
              </p>
            </div>
          </section>

          <section className="index-view__second-section">
            <div className="main-second-header index-view__second-section-box">
              <a
                href="https://opensea.io/collection/thepolacy"
                rel="noopener noreferrer"
                target="_blank"
                className=""
              >
                <img
                  className="radius theshadow index-view__second-section-image"
                  src={secondHeaderThePolacy}
                  alt="The polacy second image"
                />
              </a>
            </div>

            {/* <button
              className="index-view__game-btn red-btn theshadow d-block btn-clicked"
              onClick={() => setShowGame(true)}
            >
              Poskacz na koniu
            </button> */}

            <a
              href="/gra"
              className="theshadow radius red-btn d-block btn-clicked max-w-[200px] mt-6 p-4 mx-auto text-center"
              target="_blank"
              rel="noopener"
            >
              Poskacz na koniu!
            </a>

            {/* <button
              className="index-view__game-btn red-btn theshadow d-block btn-clicked"
              onClick={() => setShowGame(true)}
            >
              Poskacz na koniu
            </button> */}

            <ModalWrapper
              isVisible={showGame}
              action={function (): void {
                setShowGame(false);
              }}
              ChosenModal={
                <iframe
                  width={"70%"}
                  height="60%"
                  src="https://gra.thepolacy.pl/"
                />
              }
            />
          </section>

          <PolacySummarySection />

          <section className="dotted-section">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </section>

          <ReasonsSection />
          <PolacyExamplesSection />
          <PartnersSection />
          <DropsSection />
          <MemesSection serverData={serverData?.memes ?? []} />
          <TimetableSection />
          <MerchSection />

          <LeaderBoardSection />
          <BlogSection />

          <Footer />

          <a
            id="scroll-top"
            className="index-view__footer-button"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <FontAwesomeIcon icon={faLongArrowUp} color="#fff" />
          </a>
        </main>
      </Web3Providers>
    </>
  );

  if (accountDetails?.address !== undefined && ethProvider !== null) {
    mainPageContent = <DashboardPage serverData={serverData} />;
  }

  return mainPageContent;
};

export default IndexPage;
export const Head: HeadFC = () => <SEO />;

// export { Head } from "../components/Head";

export async function getServerData() {
  try {
    const res = await Announce.getAnnouncements();
    if (res instanceof Error) throw res;
    const announcements = res.filter(event => event.ShouldShow === true);

    const memesResponse = await MemesService.getMemes();
    if (memesResponse instanceof Error) throw memesResponse;
    const memes = memesResponse.filter(meme => meme.TweetId !== undefined);

    return {
      props: {
        announcements: announcements,
        memes: memes,
      },
    };
  } catch (err) {
    console.error(err);
    return {};
  }
}
