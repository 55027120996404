import * as React from "react";
import { useWeb3Provider } from "../../../contexts/AppContext";
import hoodie from "../../../images/polak-hoodie-nft.svg";
import flipFlops from "../../../images/flip-flops-transparent.png";
import hat from "../../../images/hat.png";

import { isEthereumNetworkChosen, trimAddress } from "../../../utils";
import "./MerchSection.scss";

export const MerchSection = () => {
  const webProvider = useWeb3Provider();
  return (
    <>
      {" "}
      <section
        className="merch-section drops-section aos-animate aos-after"
        id="merch"
        data-aos="fade-up"
      >
        <div className="merch-section__box">
          <div className="title merch-section__content">
            <img
              src={hat}
              alt="Cap image"
              className="merch-section__main-image"
            />
            <span className="merch-section__title big-title">Polski Merch</span>
            <p className="merch-section__first-description">
              Kochasz ojczyzne, pierogi i zimną wódeczkę? To raczej pokochasz
              też ten polski merch. Sprawdź SŁAG Polaka zdecenetralizowanego.
            </p>

            <p className="merch-section__third-description">
              <span className="merch-section__second-description">
                Masz ThePolaka?
              </span>
              Wydrukuj go sobie na bluzie albo koszulce. Możesz też zamówić
              Polskie klapki kubota w zestawie ze stylowymi skietami.
            </p>
            {/* <a
              className={`red-btn btn-clicked ${
                !isEthereumNetworkChosen(webProvider.evmNetworkChosen)
                  ? "opacity-50"
                  : ""
              }`}
              data-tooltip-id={
                !isEthereumNetworkChosen(webProvider.evmNetworkChosen)
                  ? "wrong-network"
                  : ""
              }
              data-tooltip-content={
                !isEthereumNetworkChosen(webProvider.evmNetworkChosen)
                  ? "Wybierz Mainnet Ethereum i odśwież stronę 👀"
                  : ""
              } */}
            <button
              className={`merch-section__button red-btn theshadow btn-clicked ${
                !isEthereumNetworkChosen(webProvider?.evmNetworkChosen)
                  ? "opacity-50"
                  : ""
              }`}
              onClick={async () => {
                if (!isEthereumNetworkChosen(webProvider?.evmNetworkChosen))
                  return;
                await webProvider.handleLogin();
              }}
              data-tooltip-id={
                !isEthereumNetworkChosen(webProvider?.evmNetworkChosen)
                  ? "wrong-network"
                  : ""
              }
              data-tooltip-content={
                !isEthereumNetworkChosen(webProvider?.evmNetworkChosen)
                  ? "Wybierz Mainnet Ethereum i odśwież stronę 👀"
                  : ""
              }
            >
              {webProvider.accountDetails?.address !== undefined
                ? trimAddress(webProvider.accountDetails.address)
                : "Podepnij wallet"}
            </button>
          </div>
          <div className="merch-section__products-box">
            <div className="merch-section__product ">
              <div className="product-ad merch-section__product-box theshadow radius">
                <img
                  src={hoodie}
                  alt="Bluza"
                  className="merch-section__product-image"
                />
                <div className="merch-section__bottom-box theshadow">
                  <span className="merch-section__product-name">
                    Bluzy i koszulki od Artifact
                  </span>
                  <a
                    href="https://shop.artifactshop.io/en/products/dwupak-bluza-koszulka-the-polacy-52.html"
                    target="_blank"
                    rel="noopener"
                    className="yellow-btn theshadow merch-section__product-button d-block btn-clicked"
                  >
                    Idź do sklepu
                  </a>
                </div>
              </div>
            </div>

            <div className="merch-section__product">
              <div className="product-ad merch-section__product-box theshadow radius">
                <img
                  src={flipFlops}
                  alt="Klapki"
                  className="merch-section__product-image"
                />
                <div className="merch-section__bottom-box theshadow">
                  <span className="merch-section__product-name">
                    Klapki od Kubota
                  </span>
                  <a
                    href="https://www.uniqly.io/stores/thepolacy?embedded=z3MzIX7tIT"
                    target="_blank"
                    rel="noopener"
                    className="yellow-btn theshadow merch-section__product-button d-block btn-clicked"
                  >
                    Idź do sklepu
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
