import * as React from "react";
import { useWeb3Provider } from "../../../contexts/AppContext";
import { isEthereumNetworkChosen, trimAddress } from "../../../utils";
import {
  DropElement,
  SmallDropElement,
} from "../../Items/DropElement/DropElement";
import "./DropsSection.scss";

import BitomatSvg from "../../../images/bitomat.svg";
import BluzaSvg from "../../../images/bluza.svg";
import ticket from "../../../images/ticket-image.png";
import star from "../../../images/star.png";

export const DropsSection = () => {
  const webProvider = useWeb3Provider();

  return (
    <section className="drops-section-box drops-section" id="drops">
      <div className="drops-section-box__content ">
        <div className="drops-section-box__about title" data-aos="fade-left">
          <img
            src={ticket}
            alt="Obrazek bilet"
            className="drops-section-box__ticket"
          />
          <span className="drops-section-box__header">Polskie dropy</span>
          <p className="drops-section-box__first-description">
            ThePolacy NFT współpracują z najtęższymi umysłami polskiej sceny
            Web3, żeby zapewnić swoim rodakom różnego rodzaju dropy, benefity i
            atrakcje, często dostępne wyłącznie “z pod lady”!
          </p>

          <p className="drops-section-box__first-description">
            <span className="drops-section-box__second-description">
              Jesteś ThePolakiem?
            </span>
            To masz szansę zgarnąć darmowe gadżety, zniżki w bitomatach, merch
            od partnerów, zniżki do kantorów crypto i wjazdy na zamknięte
            imprezy
          </p>

          <p className="drops-section-box__third-description">
            Sąsiedzi będą pytać skąd wziął na to pieniążki.
          </p>
          <button
            className={`merch-section__button red-btn theshadow btn-clicked ${
              !isEthereumNetworkChosen(webProvider?.evmNetworkChosen)
                ? "opacity-50"
                : ""
            }`}
            onClick={async () => {
              if (!isEthereumNetworkChosen(webProvider?.evmNetworkChosen))
                return;
              await webProvider.handleLogin();
            }}
            data-tooltip-id={
              !isEthereumNetworkChosen(webProvider?.evmNetworkChosen)
                ? "wrong-network"
                : ""
            }
            data-tooltip-content={
              !isEthereumNetworkChosen(webProvider?.evmNetworkChosen)
                ? "Wybierz Mainnet Ethereum i odśwież stronę 👀"
                : ""
            }
          >
            {webProvider.accountDetails?.address !== undefined
              ? trimAddress(webProvider.accountDetails.address)
              : "Podepnij wallet"}
          </button>

          <p className="drops-section-box__fourth-description">
            … ale przygotuj się na zalotne spojrzenia od Pani z warzywniaka,
            pytania skąd wziąłeś na to pieniążki or osiedlowego kabla czy
            zaproszenia za winkiel na piwko od największego handlarza na
            dzielni, który chciałby się skumać. Życie Polaka Zdecentralizowanego
            niesie ze sobą wiele pokus i możliwości!{" "}
          </p>
        </div>

        <div className="drops-section-box__drops" data-aos="fade-right">
          <img
            src={star}
            alt="Gwiazda wow"
            className="drops-section-box__star"
          />
          <SmallDropElement
            title={"Zniżki do Cashify i Ari10 "}
            additionalClassNames="drops-section-box__third-drop"
            hideButton={true}
          />
          <DropElement
            title={"Merch od Artifact"}
            text={
              "Wysokiej jakości bluzy i t-shitry, projektowane i szyte w Polsce."
            }
            imgUrl={BluzaSvg}
            alt="Bluza"
            actionTitle={"Odbierz"}
            action={function () {
              // setBitomatModal(true);
            }}
            bgColorHex={"#D9DBFB"}
            additionalClassNames="drops-section-box__drop-additional drops-section-box__first-drop"
            hideButton={true}
          />
          <DropElement
            title={"Znizki na Bitomaty"}
            text={
              "Wymień Bitcoina, Ethereum, Tether i USDC na jedyną prawdziwą walutę - PLN."
            }
            imgUrl={BitomatSvg}
            alt="Bitomat"
            actionTitle={"Odbierz"}
            action={function () {
              // setBitomatModal(true);
            }}
            bgColorHex={"#D9DBFB"}
            additionalClassNames="drops-section-box__drop-additional drops-section-box__second-drop"
            hideButton={true}
          />
        </div>
      </div>
    </section>
  );
};
