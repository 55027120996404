import * as React from "react";
import { LeaderBoard } from "../../LeaderBoard/LeaderBoard";
import "./LeaderBoardSection.scss";

export const LeaderBoardSection = () => (
  <>
    {" "}
    <section
      className="leaderboard leader-board-section aos-animate aos-after"
      id="toppolacy"
      data-aos="fade-up"
    >
      <h2 className="leader-board-section__header big-title">Top ThePolacy</h2>
      <p className="leader-board-section__description">
        Poznajcie naszych dumnych posiadaczy ThePolaków. To pasjonaci NFT, o
        wybitnym guście. Razem tworzymy piękną społeczność, która codziennie
        sobie pomaga, wymienia się poglądami i rozwija, dzięki czemu staje się
        jeszcze silniejsza!
      </p>
      <LeaderBoard />
    </section>
  </>
);
